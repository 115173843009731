<script lang="ts" setup>
import { ref, computed, watch } from "vue";
import { mdiScriptTextPlayOutline } from "@mdi/js";
import { getRoleName } from "@/services/api/iam.api";

const props = defineProps({
  roleId: undefined,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const breadCrumbName = ref(undefined);

watch(
  () => props.roleId,
  () => {
    getBreadCrumbName();
  },
  { immediate: true }
);

const breadcrumbs = computed(() => {
  let list = [{ default: "IAM-RolesOverview" }];
  if (props.addModus) {
    list.push({
      text: "toevoegen",
      disabled: true,
    });
  } else {
    list.push({
      text: breadCrumbName.value,
      to: { name: "IAM-RoleDetails", params: { roleId: props.roleId } },
    });
  }
  return list;
});

async function getBreadCrumbName() {
  if (!props.addModus) {
    const response = await getRoleName(props.roleId);
    breadCrumbName.value = response.data;
  }
}

function updateBreadCrumbName() {
  console.log("update breadCrumbName role");
  getBreadCrumbName();
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
    <v-container fluid>
      <header class="app-welcome-message">
        <h2>
          <v-icon>{{ mdiScriptTextPlayOutline }}</v-icon> Rol
        </h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-RoleDetails' }" :disabled="addModus">
                <v-list-item-title>Details</v-list-item-title>
              </v-list-item>
              <v-list-item text :to="{ name: 'IAM-RolePermissions' }" :disabled="addModus">
                <v-list-item-title>Rechten</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-RoleUsers' }" :disabled="addModus">
                <v-list-item-title>Gebruikers</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-RoleUserGroups' }" :disabled="addModus">
                <v-list-item-title>Groepen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-RoleResources' }" :disabled="addModus">
                <v-list-item-title>Resources</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-RoleProjects' }" :disabled="addModus">
                <v-list-item-title>Projecten</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <router-view @update-role="updateBreadCrumbName"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
